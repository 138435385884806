import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import React from "react";

import Layout from "../components/Layout";
import LogoAnimated from "../components/LogoAnimated";
import Seo from "../components/Seo";
import SignUpButton from "../components/SignUpButton";

const useStyles = makeStyles(() => ({
  screenshotContainer: {
    overflow: "hidden",
  },
  featureLogoSection: {},
  featureLogo: {
    minWidth: 75,
  },
  subtitle: {
    marginBottom: 100,
  },
  video: {
    maxWidth: "100%",
    display: "block",
  },
  keyValues: {
    marginBottom: 100,
  },
}));

const features = [
  "Unlimited accounts",
  "Unlimited categories",
  "Unlimited transactions",
];

const IndexPage = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Seo title="Pricing" />
      <Box my={4}>
        <Grid
          alignItems="center"
          container
          justify="space-between"
          direction="row"
        >
          <Grid item>
            <Typography color="primary" variant="h1">
              Pricing
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              alignItems="center"
              className={classes.featureLogoSection}
              container
              justify="center"
              direction="row"
            >
              <Grid className={classes.featureLogo} item>
                <LogoAnimated />
              </Grid>
              <Grid item>
                <Typography color="primary" variant="h3">
                  Sumit
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid alignItems="center" container justify="center" direction="row">
        <Grid item md={4}>
          <Card>
            <CardContent>
              <Typography variant="h1" align="center">
                $0/mo
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                align="center"
              >
                for now.
              </Typography>
              <List>
                {features.map((f) => (
                  <ListItem key={f}>
                    <ListItemIcon>
                      <Box color="success.main">
                        <CheckIcon color="inherit" />
                      </Box>
                    </ListItemIcon>
                    <ListItemText primary={f} />
                  </ListItem>
                ))}
              </List>
              <SignUpButton fullWidth />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IndexPage;
